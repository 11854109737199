<template>
  <router-link :to="toRoute">
    <v-btn depressed class="mb-4 ml-0 mr-3 add-button" color="primary" dark>
      {{ label }}
      <v-icon right >mdi-plus</v-icon>
    </v-btn>
  </router-link>
</template>

<script>
export default {
  props: ["toRoute", "label"],
};
</script>
<style scoped>
/* @media only screen and (max-width: 568px) {
  .add-button {
    width: 100%;
  }
} */
</style>
